/**
 * @generated SignedSource<<ba609acf14e55d6e1d2d852f7a90f859>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EdsJobSync = "EDS_JOB_SYNC_CUSTOM_ATTRIBUTES" | "EDS_JOB_SYNC_CUSTOM_EVENTS" | "EDS_JOB_SYNC_ECOMMERCE_ADD_TO_CARTS" | "EDS_JOB_SYNC_ECOMMERCE_PRODUCT_VIEWS" | "EDS_JOB_SYNC_ECOMMERCE_PURCHASES" | "EDS_JOB_SYNC_MOBILE_WALLET" | "EDS_JOB_SYNC_PRODUCT_CATALOG" | "EDS_JOB_SYNC_SUBSCRIBER_OPT_INS" | "EDS_JOB_SYNC_SUBSCRIBER_OPT_OUTS" | "EDS_JOB_SYNC_TEXT_SEND" | "EDS_JOB_SYNC_TEXT_SEND_BULK" | "EDS_JOB_SYNC_UNKNOWN" | "EDS_JOB_SYNC_USER_IDENTIFIERS" | "%future added value";
export type EdsJobSyncMethod = "EDS_JOB_SYNC_METHOD_AMAZON_S3" | "EDS_JOB_SYNC_METHOD_ATTENTIVE_SFTP" | "EDS_JOB_SYNC_METHOD_CLIENT_HOSTED_SFTP" | "EDS_JOB_SYNC_METHOD_MANUAL_UPLOAD" | "EDS_JOB_SYNC_METHOD_S3" | "EDS_JOB_SYNC_METHOD_SFTP" | "EDS_JOB_SYNC_METHOD_SNOWFLAKE_DATA_SHARE" | "EDS_JOB_SYNC_METHOD_UNKNOWN" | "%future added value";
export type SyncRunDetail_EDSLib_Query$variables = {
  syncId: string;
};
export type SyncRunDetail_EDSLib_Query$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"SyncRunDetailBody_EDSLib_Sync">;
  } | null;
};
export type SyncRunDetail_EDSLib_Query$rawResponse = {
  readonly node: {
    readonly __typename: "Sync";
    readonly description: string;
    readonly id: string;
    readonly name: string;
    readonly syncMethod: EdsJobSyncMethod;
    readonly syncType: EdsJobSync;
  } | {
    readonly __typename: string;
    readonly id: string;
  } | null;
};
export type SyncRunDetail_EDSLib_Query = {
  rawResponse: SyncRunDetail_EDSLib_Query$rawResponse;
  response: SyncRunDetail_EDSLib_Query$data;
  variables: SyncRunDetail_EDSLib_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "syncId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "syncId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SyncRunDetail_EDSLib_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SyncRunDetailBody_EDSLib_Sync"
              }
            ],
            "type": "Sync",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SyncRunDetail_EDSLib_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "syncMethod",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "syncType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              }
            ],
            "type": "Sync",
            "abstractKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "22f59616e03978e30124fcd49a5e4261",
    "id": null,
    "metadata": {},
    "name": "SyncRunDetail_EDSLib_Query",
    "operationKind": "query",
    "text": "query SyncRunDetail_EDSLib_Query(\n  $syncId: ID!\n) {\n  node(id: $syncId) {\n    __typename\n    ... on Sync {\n      ...SyncRunDetailBody_EDSLib_Sync\n    }\n    id\n  }\n}\n\nfragment SyncRunDetailBody_EDSLib_Sync on Sync {\n  name\n  syncMethod\n  syncType\n  description\n}\n"
  }
};
})();

(node as any).hash = "c38b225c68f70ef422abf86c2040eff4";

export default node;
